import './Despre.scss';
import antet_pocu from './antet-pocu.jpg';

const Despre = () => (
  <>
    <div className="despre-container">
      <aside className="despre-text">
        <h1>Despre noi</h1>
        <p>
          De îndată ce veți ajunge la cabană, veți fi cuceriți de frumusețea
          mirifică a zonei și veți fi învăluiți de căldura primitoare a cabanei.
        </p>

        <main>
          Cabana dispune de <b>6 camere</b> încântătoare, fiecare cu
          <b> baie proprie</b>, ce pot acomoda pana la <b>14 persoane</b>.
          <p>De asemenea, cabana vă oferă urmatoarele facilități:</p>
          <div className="icons">
            {icons.map((icon, index) => (
              <div key={index} className="icons-wrapper">
                <img src={icon.src} alt={icon.titlu} title={icon.titlu} />
                <p>{icon.titlu}</p>
              </div>
            ))}
          </div>
          <hr />
          <p style={{ marginTop: '30px' }}>
            La cabană veți fi invitați să serviți din bunătățile tradiționale
            gătite chiar in bucătăria proprie, într-un frumos salon rustic. Apoi
            puteți petrece timp de calitate alături de cei dragi într-un
            încântător living, chiar în fața semineului. Cabana Brătei vă pune
            la dispoziție <b>parcarea gratuită</b>.
          </p>
        </main>
        <div className="despre-shapes">
          <div className="despre-circle-big" />
          <div className="despre-circle-small" />
        </div>
      </aside>
      <aside className="despre-poza">
        <div className="img-wrapper">
          <img
            src={process.env.PUBLIC_URL + `/images/image-2.jpg`}
            alt="cabana exterior"
          />
        </div>
      </aside>
    </div>
    <div className="finantare-container">
      <hr />
      <img src={antet_pocu} className="finantare" alt="antet finantare pocu" />
      <section>
        <b>Afacerea este finanțată prin:</b>
        <ul className="list" style={{ listStyle: 'circle !important' }}>
          <li>PROGRAMUL OPERAȚIONAL CAPITAL UMAN 2014-2020</li>
          <li>Axa prioritară 4: Incluziunea socială și combaterea sărăciei.</li>
          <li>
            Obiectivul tematic 9: Promovarea incluziunii sociale, combaterea
            sărăciei și a oricărei forme de discriminare.
          </li>
          <li>
            Prioritatea de investiții 9v: Promovarea antreprenoriatului social
            și a integrării vocaționale în inteprinderile sociale și economia
            socială și solidară pentru a facilita accesul la ocuparea forței de
            muncă.
          </li>
          <li>Denumire proiect: "SUCCES - Economie Sociala - ID 128638"</li>
        </ul>
        <b>Obiectiv specific(O.S.) 4.16:</b>
        <ul>
          <li>
            Consolidarea capacității inteprinderilor de economie socială de a
            funcționa într-o manieră auto-sustenabila.
          </li>
        </ul>
      </section>
      <hr />
    </div>
  </>
);

export default Despre;

const icons = [
  {
    src: process.env.PUBLIC_URL + `pictograme/Gradina.png`,
    titlu: 'Grădină',
  },
  {
    src: process.env.PUBLIC_URL + `pictograme/Gratar.png`,
    titlu: 'Grătar',
  },
  {
    src: process.env.PUBLIC_URL + `pictograme/Semineu.png`,
    titlu: 'Șemineu',
  },
  {
    src: process.env.PUBLIC_URL + `pictograme/Terasa.png`,
    titlu: 'Terasă',
  },
  {
    src: process.env.PUBLIC_URL + `pictograme/Tv.png`,
    titlu: 'TV',
  },
  {
    src: process.env.PUBLIC_URL + `pictograme/Parcare.png`,
    titlu: 'Parcare',
  },
];
