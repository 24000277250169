/* eslint-disable jsx-a11y/anchor-is-valid */
import './Footer.scss';

const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className="left-side">
          <div className="logo-footer">© Cabana Brătei 2021</div>
          <a
            href="https://www.facebook.com/Cabana_Bratei-111311577861653/"
            target="_blank"
            className="social-footer"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com/cabana_bratei/"
            target="_blank"
            className="social-footer"
            rel="noreferrer"
          >
            Instagram
          </a>
        </div>
        <div className="right-side">
          Un produs&nbsp;
          <a
            onClick={() => {
              window.open('https://beesocial.ro/');
              return false;
            }}
          >
            ©BeeSocial
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
