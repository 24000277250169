import './App.scss';

import { Suspense, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { lazy } from 'react';

import Navbar from '../Navbar/Navbar';
import Main from '../Main/Main';
import Despre from '../Despre/Despre';
import Footer from '../Footer/Footer';
import Loader from '../Loader/Loader';

const Imprejurimi = lazy(() => import('../pages/Imprejurimi/Imprejurimi.js'));
const Camere = lazy(() => import('../pages/Camere/Camere'));
const Contact = lazy(() => import('../pages/Contact/Contact'));
const Localizare = lazy(() => import('../pages/Localizare/Localizare.js'));

function App() {
  useEffect(() => {
    //microsoft clarity
    const script = document.createElement('script');

    script.type = `text/javascript`;
    script.async = true;
    script.innerHTML = `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "clefy3v89u");`;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Suspense fallback={<Fallback />}>
      <AnimatePresence>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Loader />
              <Main />
              <Despre />
            </Route>

            <Route path="/imprejurimi/">
              <Imprejurimi />
            </Route>

            <Route path="/camere">
              <Camere />
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>

            <Route path="/localizare">
              <Localizare />
            </Route>

            <Route path="/*">
              <FourOhFour />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </AnimatePresence>
    </Suspense>
  );
}

export default App;

const Fallback = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fallback"
  ></motion.div>
);

const FourOhFour = () => (
  <div className="four-o-four">
    <h1>Pagina nu există</h1>
    <Link to="/">
      <p>Înapoi la pagina principală</p>
    </Link>
  </div>
);
