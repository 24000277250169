import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import './Main.scss';

const Main = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 1.5 }}
    className="main-container-big"
  >
    <div className="circle-wrapper">
      <div className="big-circle" />
      <div className="small-circle" />
    </div>
    <div className="main-container">
      <aside className="text-side">
        <div className="text-wrap">
          <h1>Te invităm la Cabana Brătei</h1>
          <p>
            Liniște, aer curat, relaxare și o stare de bine în general, sunt
            doar câteva dintre promisiunile pe care Cabana Brătei le face
            tuturor celor care îi vor călca pragul și care vor sa uite de
            grijile orașului și doresc reconectare cu natura.
          </p>

          <Link to="/contact">
            <button>Contacteaza-ne</button>
          </Link>
        </div>
      </aside>
      <aside className="img-side">
        <div className="img-wrapper">
          <img
            className="main-img"
            src={process.env.PUBLIC_URL + `/images/image-1.jpg`}
            alt="cabana"
          />
        </div>
      </aside>
    </div>
  </motion.div>
);

export default Main;
