import { FC } from 'react';
import './Navbar.scss';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const linkStyle = {
  textDecoration: 'none',
  color: '#7d643f',
};

const Navbar: FC = () => {
  const [width, setWith] = useState<number>(window.innerWidth);

  const { innerWidth, innerHeight } = window;
  const [isClicked, setClick] = useState(false);
  const [executed, setExecuted] = useState(false);

  const location = useLocation();

  // useEffect(() => {
  //   console.log(location.pathname);
  // }, [location]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      const { innerWidth, innerHeight } = window;
      setWith(innerWidth);
    });

    if (isClicked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () =>
      window.removeEventListener('resize', () => {
        const { innerWidth, innerHeight } = window;
      });
  }, [isClicked]);

  return (
    <>
      {innerWidth > 768 ? (
        <motion.nav
          className="nav-desktop"
          initial={{ y: !executed ? '-70px' : 0 , opacity: !executed ? 0: 1 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.5, ease: 'easeInOut', duration: 0.7 }}
          onAnimationComplete={() => setExecuted(true)}
        >
          <div className="logo">
            <Link style={linkStyle} to="/">
              Cabana Brătei
            </Link>
            <FirmaDeAur clasa='firma-de-aur-desktop' />
          </div>
          <div className="links">
            <Link style={linkStyle} to="/localizare">
              <div>Localizare</div>
            </Link>
            <Link style={linkStyle} to="/imprejurimi">
              <div>Împrejurimi</div>
            </Link>

            <Link style={linkStyle} to="/camere">
              <div>Camere</div>
            </Link>
            <Link style={linkStyle} to="/contact">
              <div>Contact</div>
            </Link>
          </div>
        </motion.nav>
      ) : (
        <>
          {
            //mobile navbar
          }
          <motion.nav
            className="nav-mobile"
            initial={{ y: !executed ? '-70px' : 0, opacity: !executed ? 0  : 1}}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.5, ease: 'easeInOut', duration: 0.7 }}
            onAnimationComplete={() => setExecuted(true)}
          >
            <Link className="nav-el " style={linkStyle} to="/">
              <div className="logo">Cabana Brătei</div>
            </Link>
            <FirmaDeAur clasa='firma-de-aur-mobil' />

            <div
              onClick={() => {
                setClick(!isClicked);
              }}
              className="sandwich_container"
            >
              <div className="sandwich_wrapper hamburger">
                <motion.div
                  initial={{ rotateZ: '0deg', x: '0' }}
                  animate={{
                    rotateZ: isClicked ? '45deg' : '0deg',
                    y: isClicked ? '4.25px' : '0',
                  }}
                  transition={{ duration: 0.2, type: 'keyframess' }}
                  className="line1"
                ></motion.div>
                <motion.div
                  className="line2"
                  initial={{ rotateZ: '0deg', x: '0' }}
                  animate={{
                    rotateZ: isClicked ? '-45deg' : '0deg',
                    y: isClicked ? '-4.25px' : '0',
                  }}
                  transition={{ duration: 0.2, type: 'keyframess' }}
                ></motion.div>
              </div>
            </div>
          </motion.nav>
          <motion.div
            className="sideBar"
            initial={{ x: '100%', opacity: 0 }}
            animate={{ x: isClicked ? '0%' : '100%', opacity: 1 }}
            transition={{ duration: 0.7, ease: [0.19, 1, 0.22, 1] }}
          >
            <div className="links">
              <Link
                onClick={() => setClick(false)}
                style={linkStyle}
                to="/localizare"
              >
                <motion.div
                  initial={{ x: '100px', opacity: 0 }}
                  animate={{
                    x: isClicked ? 0 : '100px',
                    opacity: isClicked ? 1 : 0,
                  }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                >
                  Localizare
                </motion.div>
              </Link>

              <Link
                onClick={() => setClick(false)}
                style={linkStyle}
                to="/imprejurimi"
              >
                <motion.div
                  initial={{ x: '100px', opacity: 0 }}
                  animate={{
                    x: isClicked ? 0 : '100px',
                    opacity: isClicked ? 1 : 0,
                  }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                >
                  Împrejurimi
                </motion.div>
              </Link>
              <Link
                onClick={() => setClick(false)}
                style={linkStyle}
                to="/camere"
              >
                <motion.div
                  initial={{ x: '100px', opacity: 0 }}
                  animate={{
                    x: isClicked ? 0 : '100px',
                    opacity: isClicked ? 1 : 0,
                  }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                >
                  Camere
                </motion.div>
              </Link>
              <Link
                onClick={() => setClick(false)}
                style={linkStyle}
                to="/contact"
              >
                <motion.div
                  initial={{ x: '100px', opacity: 0 }}
                  animate={{
                    x: isClicked ? 0 : '100px',
                    opacity: isClicked ? 1 : 0,
                  }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                >
                  Contact
                </motion.div>
              </Link>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: isClicked ? 1 : 0 }}
              transition={{ delay: 0.3, duration: 0.3, ease: 'easeInOut' }}
              className="social-side"
            >
              <hr className="horizontal-line" />

              <a
                className="facebook-m"
                href="https://www.facebook.com/Cabana_Bratei-111311577861653/"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
              <br />
              <a
                className="instagram-m"
                href="https://www.instagram.com/cabana_bratei/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </motion.div>
          </motion.div>
        </>
      )}
    </>
  );
};
export default Navbar;

const FirmaDeAur = ({clasa}:{clasa: string}) => (
  <div className={`firma-de-aur ${clasa}`}>
    <a
      href="https://www.firmadeaur.ro/company/cabana-bratei-4306259"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="https://www.firmadeaur.ro/company-image/4306259-dark.png"
        style={{
          width: '250px',
          height: '60px',
          border: '0',
          margin: 0,
        }}
        alt="Cabana Brătei - Dobreşti"
        title="Cabana Brătei - Dobreşti"
      />
    </a>
  </div>
);
