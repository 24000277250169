import './Loader.scss';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

const Loader = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
      document.body.style.overflow = 'auto';
    }, 1700);
  });

  return (
    <motion.div
      animate={{ display: 'none' }}
      transition={{ delay: 1.8 }}
      className="loader-wrapper"
    >
      <motion.div
        animate={{ opacity: 0 }}
        transition={{
          delay: 1.3,
          ease: 'easeInOut',
          duration: 0.4,
        }}
        className="loader-container"
      >
        <motion.h1
          animate={{ y: [0, -40], opacity: [0, 1] }}
          transition={{ duration: 0.7, delay: 0.3 }}
        >
          Cabana
        </motion.h1>
        <motion.h1
          animate={{ y: [0, 40], opacity: [0, 1] }}
          transition={{ duration: 0.7, delay: 0.3 }}
        >
          Brătei
        </motion.h1>
      </motion.div>
    </motion.div>
  );
};

export default Loader;
